<template>
  <div id="add-leave">
    <v-snackbar :color="snackbarColor"  top v-model="kayitYapildi">
      <span v-if="type == 'add'"> {{ snackbarMessage }}</span>
      <span v-else>Kayıt başarı ile güncellendi..</span>
    </v-snackbar>

    <v-card tile :flat="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'">
      <v-toolbar :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
        <v-toolbar-title  class="text-body-2 text-md-h6 font-weight-medium">
          <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
            {{ icons.mdiCalendarClock }}
          </v-icon>
          İzin Girişi
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>

      <v-form style="padding: 20px" ref="form" @submit.prevent="sendFields">
        <v-row no-gutters>
          <v-col cols="12" md="3" class="pa-2 d-flex align-center">
            <label for="izin_tipi" class="label-small">İzin Tipi</label>
          </v-col>
         
          <v-col cols="12" md="3" class="pa-2">
            <v-autocomplete
              v-model="izin_tipi"
              :items="[ {STEXT: 'Seçiniz', LEAVECODE: null}, ...izinTipleri]"
              item-text="STEXT"
              item-value="LEAVECODE"
              label="İzin Tipi Seçin"
              outlined
              dense
              hide-details
              :rules="[v => !!v || 'Seçiniz']"
              return-object
              required
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row no-gutters v-if="izin_tipi && izin_tipi.LEAVECODE !== null">
          <v-col cols="12" md="3" class="pa-2 d-flex align-center">
            <label for="maks_izin_gunu">Maks. İzin Günü</label>
          </v-col>
          <v-col cols="12" md="3" class="pa-2">
            <v-text-field 
              v-model="maks_izin_gunu" 
              outlined 
              dense 
              hide-details 
              :rules="[required]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters v-if="izin_tipi && izin_tipi.LEAVECODE !== null">
          <v-col cols="12" md="3" class="pa-2 d-flex align-center">
            <label for="bas_tarih">Başlangıç Tarihi</label>
          </v-col>
          <v-col cols="12" md="3" class="pa-2">
            <v-menu v-model="menu1" :close-on-content-click="true" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field 
                  id="bas_tarih" 
                  v-model="computedDateFormatted"                  
                  persistent-hint 
                  :prepend-icon="icons.mdiCalendar" 
                  v-bind="attrs" 
                  v-on="on" 
                  outlined 
                  dense 
                  hide-details
                  required
                ></v-text-field>
              </template>
              <v-date-picker 
                v-model="start_date" 
                no-title 
                color="primary" 
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row no-gutters v-if="izin_tipi && izin_tipi.LEAVECODE !== null">
          <v-col cols="12" md="3" class="pa-2 d-flex align-center">
            <label for="bit_tarih">Bitiş Tarihi</label>
          </v-col>
          <v-col cols="12" md="3" class="pa-2">
            <v-menu v-model="menu2" :close-on-content-click="true" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field 
                  v-model="computedDateFormattedEnd"                 
                  persistent-hint 
                  :prepend-icon="icons.mdiCalendar" 
                  v-bind="attrs" 
                  v-on="on" 
                  outlined 
                  dense 
                  hide-details
                  required
                ></v-text-field>
              </template>
              <v-date-picker 
                v-model="end_date" 
                no-title 
                color="primary" 
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row no-gutters  v-if="izin_tipi && izin_tipi.LEAVECODE !== null">
          <v-col cols="12" md="3" class="pa-2 d-flex align-center">
            <label for="half_day">Yarım Gün Seçeneği</label>
          </v-col>
          <v-col cols="12" md="3" class="pa-2">
            <v-autocomplete
              v-model="halfDayOption"
              :items="halfDayOptions"
              item-text="text"
              item-value="value"
              label="Yarım Gün Seçin"
              outlined
              dense
              hide-details                         
              @change="updateIzinSaatleri"
            ></v-autocomplete>
          </v-col>    
        </v-row>

        <v-row no-gutters v-if="izin_tipi && izin_tipi.LEAVECODE !== null">
          <v-col cols="12" md="3" class="pa-2 d-flex align-center">
            <label>İzin Saatleri</label>
          </v-col>
          <v-col cols="12" md="3" class="pa-2">
            <v-text-field
              v-model="izinSaatleri1"
              label="İlk Gün"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="pa-2">
            <v-text-field
              v-model="izinSaatleri2"
              label="Son Gün"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>


        <v-row  no-gutters v-if="izin_tipi && izin_tipi.LEAVECODE !== null">
          <v-col cols="12" md="3" class="pa-2 d-flex align-center">
            <label>Açıklama</label>
          </v-col>
          <v-col cols="12" md="6" class="pa-2">
            <v-textarea v-model="notlar" rows="4" outlined></v-textarea>
          </v-col>
        </v-row>

        <v-row no-gutters v-if="izin_tipi && izin_tipi.LEAVECODE !== null">
          <v-col offset-md="3" cols="6" class="pa-2">
            <v-btn :loading="loading" @click="validate" color="success" depressed class="white--text">
              <span v-if="type == 'add'">Kaydet</span>
              <span v-else>Güncelle</span>
              <v-icon dark right>
                {{ icons.mdiCheckboxMarkedCircleOutline }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import store from '@/store';
import { useRouter } from '@core/utils';
  import VSnackbars from 'v-snackbars'
import { mdiCalendarClock, mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import { onMounted, ref, computed ,watch} from '@vue/composition-api';

export default {
  components: {      
      VSnackbars,
    },
  setup() {
    const required = ref(value => !!value || 'Bu alan gereklidir.');
    const menu1 = ref(false);
    const menu2 = ref(false);
    const start_date = ref(moment().format('YYYY-MM-DD'));
    const end_date = ref(moment().format('YYYY-MM-DD'));
    const { router } = useRouter();
    const loading = ref(false);
    const type = ref('add');
    const kayitYapildi = ref(false);
    const snackbarMessage = ref('');
    const snackbarColor=  ref('success'); // Varsayılan renk
    const izin_tipi = ref('');
    const maks_izin_gunu = ref('0');
    const notlar = ref('');
    const yarimGun = ref('');
    const izinSaatleri1 = ref('');
    const izinSaatleri2 = ref('');
    const izinTipleri = ref([]);
    const halfDayOption = ref(''); // Bu satırı kontrol edin
    const halfDayOptions = [
      { text: 'Yok', value: '' },
      { text: 'İlk Gün Öğleden Sonra', value: 'half_first_day' },
      { text: 'Son Gün Öğleden Önce', value: 'half_last_day' }
    ];

    const updateIzinSaatleri = () => {
      if (halfDayOption.value === 'half_first_day') {
        izinSaatleri1.value = '13:00 - 18:00';
        izinSaatleri2.value = '08:00 - 18:00';
      } else if (halfDayOption.value === 'half_last_day') {
        izinSaatleri1.value = '08:00 - 18:00';
        izinSaatleri2.value = '08:00 - 13:00';
      } else {
        izinSaatleri1.value = '08:00 - 18:00';
        izinSaatleri2.value = '08:00 - 18:00';
      }
    };

    const computedDateFormatted = computed(() => moment(start_date.value).format('DD.MM.YYYY'));
    const computedDateFormattedEnd = computed(() => moment(end_date.value).format('DD.MM.YYYY'));

    onMounted(() => {
      getPersLeaveTypes();
      if (router.currentRoute.params.id) {
        type.value = 'edit';
      } else {
        type.value = 'add';
      }
    });

    const getPersLeaveTypes = val => { 
      
     
      const method = 'getPersLeaveTypes'
      store
        .dispatch('postMethod', {
          method          
        })
        .then(response => {          
          if (response.error === true) {
            return
          }           
          izinTipleri.value = response.detail;          
        })
    }   
 //izinSaatleri: [izinSaatleri1.value, izinSaatleri2.value],
 const sendFields = () => {
  debugger
  loading.value = true;
  
   
   // izin_tipi.value içindeki doğru özellikleri kullanarak leaveType'ı ayarlayın
  const leaveType = izin_tipi.value ? izin_tipi.value.LEAVECODE : null; // veya uygun başka bir özellik

  
  const startDate = start_date.value
  const endDate = end_date.value
  
  const  ltext= notlar.value
  const  half_day_first_lastv= halfDayOption.value
  const method = 'addPersLeave'
  const [startHour,endHour1] = izinSaatleri1.value.split(' - ');
  const [startHour2,endHour] = izinSaatleri2.value.split(' - ');
      store.dispatch('postMethod', {
          method,
          leaveType,
          startDate,
          endDate,
          startHour,
          endHour,
          ltext,
          half_day_first_lastv
        })
  .then(response => {
    debugger
    loading.value = false;
    if (response.error === 0) {
      kayitYapildi.value = true;
      snackbarColor.value='success'
       // Snackbar ile başarı mesajı göster
       snackbarMessage.value=("Kayıt Başarılı: İzin numarası: " + response.detail.LEAVENUM); 
    } else {
        kayitYapildi.value = true;
        snackbarColor.value = 'error'; // Hata durumu
        // Hata mesajını Snackbar ile göster
        snackbarMessage.value=(response.msg);      
    }
    setTimeout(() => {
        kayitYapildi = false; // 3 saniye sonra snackbar'ı kapat
      }, 3000);
  })
  .catch(error => {
    loading.value = false;
    kayitYapildi.value = true;
    snackbarColor.value = 'error'; // Hata durumu
     // Genel hata mesajını Snackbar ile göster
     snackbarMessage.value=("Bir hata oluştu. Lütfen tekrar deneyin.");

     setTimeout(() => {
        kayitYapildi = false; // 3 saniye sonra snackbar'ı kapat
      }, 3000);
  
  });
};

    const validate = () => {
      sendFields();
    };

    return {
      required,
      kayitYapildi,
      loading,
      izin_tipi,
      maks_izin_gunu,
      start_date,
      end_date,
      notlar,
      yarimGun,
      izinSaatleri1,
      izinSaatleri2,
      validate,
      computedDateFormatted,
      computedDateFormattedEnd,
      menu1,
      menu2,
      izinTipleri,
      halfDayOption,
      halfDayOptions,
      updateIzinSaatleri,
      snackbarMessage,
      snackbarColor,
      icons: {
        mdiCalendarClock,
        mdiCheckboxMarkedCircleOutline,
      },
      type,
    };
  },
  mounted() {
    this.updateIzinSaatleri(); // Bileşen yüklendiğinde saatleri ayarla
  }
};
</script>

<style>
label {
  color: rgb(48, 26, 26);
  font-size: 0.875rem; /* Örneğin, 14px için bu değer */
  font-weight: normal; /* Kalınlık ayarı */
}
.v-application .primary--text {
  color: var(--v-secondary-base) !important;
  caret-color: var(--v-secondary-base) !important;
}

</style>
